<template>
  <div class="_w-max">
    <div class="_w-max _mb-pad-top-50 _mb-pad-x-25" style="background-color:rgba(245, 245, 245, 1);">
      <div class=" _mb-mar-bottom-25 _flex-row-bet-mid">
        <div class="_mb-font-48 _b">{{$t('修改密码')}}</div>
        <router-link :to="'/user_mobile'" class="_flex-row-mid" style="color:rgba(66, 132, 220, 1);align-self:flex-end;">
          <img class="_mb-w-25" src="~@/assets/image/hangda/fanhui2.png" alt=""><div class="_mb-font-28 _mb-mar-left-10">{{$t('返回')}}</div>
        </router-link>
      </div>

      <div class="_white-bg _mb-font-28 _mb-mar-bottom-40" style="border-radius:6px;">
        <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
          <div>{{$t('旧密码')}}</div>
          <input class="_text-right _mb-font-28" v-model="used_pwd" :placeholder="$t('请输入')" type="text">
        </div>
        <div class="_flex-row-bet-mid _border-bottom _mb-pad-y-35 _mb-pad-x-25">
          <div>{{$t('新密码')}}</div>
          <input class="_text-right _mb-font-28" v-model="new_pwd" :placeholder="$t('请输入')" type="text">
        </div>
        <div class="_flex-row-bet-mid _mb-pad-y-25 _mb-pad-x-35">
          <div>{{$t('确认新密码')}}</div>
          <input class="_text-right _mb-font-28" v-model="affirm_pwd" :placeholder="$t('请输入')" type="text">
        </div>
      </div>

      <div @click="submit" class="_white _w-max _flex-row-center-mid _mb-font-28 _mb-h-75" style="background-color:rgba(66, 132, 220, 1);border-radius:6px;">
        {{$t('更改密码')}}
      </div>
    </div>
  </div>
</template>

<script>
import { changePwd } from '@/api/jiyun'
export default {
  name: 'MobileChangepwd',

  data() {
    return {
      title: this.$t('更改密码'),
      used_pwd: '',
      new_pwd: '',
      affirm_pwd: '',
    };
  },

  mounted() {
    
  },

  methods: {
    // 提交
    submit(){
      
      let that = this;
      let param = {
        used_pwd: this.used_pwd,
        new_pwd: this.new_pwd,
        affirm_pwd: this.affirm_pwd,
        domain_url: window.location.origin
      }
      changePwd(param).then((res)=>{
        this.$Message.success(res.msg);
        setTimeout(()=>{
          that.$router.push({path: '/user_mobile'})
        },800)
      }).catch((err)=>{
        this.$Message.error(err.msg);
      })
    },
    close() {
      this.$emit('IsShow',false);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>